@import "_reset.scss";
@import "_fonts.scss";
@import "_animate.scss";
@import "_fullpage.scss";
@import "_slider.scss";


$green: #178B42;
$yellow: #EDD418;
$grey: #F7F9F8;
$black: #4D4C4C;
$text: #504F4F;

html {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 350px) / 990));
  }
}

@media screen and (min-width: 1615px) {
  html {
    font-size: 29px
  }
}

::-moz-selection {
  color: #fff;
  background: $green;
}

::selection {
  color: #fff;
  background: $green;
}

/* Zeichnet markierten Text innerhalb von Absätzen weiß auf schwarz */
p::-moz-selection {
  color: #fff;
  background: $green;
}

p::selection {
  color: #fff;
  background: $green;
}

body {
  background: #fff;
  color: $text;
  line-height: 1.30;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
h1,h2,h3,h4,h5 {
  font-weight: 700;
  font-style: italic;
}
h1 {
  font-size: 1.60rem;
  margin-bottom: 1.2rem;
}
h2 {
  font-size: 1.6rem;
  margin-bottom: 1.15rem;
}
h3 {
  font-size: 1.0rem;
  color: $green;
  margin-bottom: 1.0rem;
}
h4 {
  font-size: 1.20rem;
  margin-bottom: 0.9rem;
}
h5 {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
}

p {
  font-size: 0.9rem;
  padding-bottom: 1rem;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
}
a {
  text-decoration: none;
  color: $green;
  transition: all ease 0.5s;
}
a:hover {
  transition: all ease 0.5s;
}
b {
  font-weight: 600;
}

.content .button {
  margin: 0.4rem 0;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  background: $yellow;
  padding-right: 3rem;
  position: relative;
  span {
    padding: 0.9rem 1.4rem;
    display: inline-block;
    border-right: 1px solid white;
  }
  &:after {
    content: '';
    height: 1.0rem;
    width: 3rem;
    background: url('../img/arrowdown.svg') no-repeat center center ;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 0;
  }
  &:hover {
    box-shadow: 6px 6px 21px rgba(0,0,0,0.20);
    color: #fff;
  }
  &:hover:after {
    -webkit-animation:spin 0.30s linear;
    -moz-animation:spin .030s linear;
    animation:spin 0.30s linear;

  }
}
.background-zoom {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: no-repeat 50% 50%;
  background-size: cover;


  //-webkit-animation-timing-function: linear;
  //animation-timing-function:ease-in;
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  animation-fill-mode:forwards;
  -webkit-animation-fill-mode:forwards;


  z-index: 3;
  &.gostart {
    -webkit-animation-name: kenburns-1;
    animation-name: kenburns-1;
  }
}

.rechte {
  font-size: 0.7rem;
}

 @-webkit-keyframes
 kenburns-1 {
     0% {
  opacity: 1;
  -webkit-transform: scale(1.0);
  transform: scale(1.00);
 }

   100% {
    opacity: 1;
   -webkit-transform: scale(1.25);
   transform: scale(1.25);
   }

}
 @keyframes
kenburns-1 {
    0% {
 opacity: 1;
 -webkit-transform: scale(1.00);
 transform: scale(1.00);
}

  100% {
   opacity: 1;
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  }


}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.content ul {
  margin: 0.8rem 0;
  li {
    padding-left: 1.8rem;
    padding-bottom: 0.85rem;
    font-weight: 600;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0.15rem;
      left: 0;
      height: 1rem;
      width: 1rem;
      background: url('../img/icn_check.svg') no-repeat left top;
      background-size: contain;
    }
  }

}
.content a {
  &:hover {
    color: $yellow;
  }
}
.images{
	width: 100%;
	height: 100%;
  z-index: 100;
	position: absolute;
	overflow:hidden;
	img{
		position: absolute;
	}
}
.section {
  overflow: hidden;
  padding-top: 2rem;
}
#section0 {
  background: $green;
  .background-zoom {
    background-image: url('../img/header-mob.jpg');
  }

  padding-bottom: 4rem;
  min-height: 700px;
  min-height: calc(100vh - 70px);
  display: flex;
   flex-flow: column nowrap;
   flex-direction: column;

  .container-fluid-fb {
    margin-top:auto;
  }
}

#section2 {
  background: $grey;
  .about {
    height:100%;
    min-height: 300px;
    position: relative;
    overflow: hidden;
    //background: url('../img/about.jpg') no-repeat center center;
    //background-size: cover;
    .background-zoom {
      background-image: url('../img/about.jpg');
    }

  }

}
#section4 {
  background: $grey;
}
.team {
  display: flex;
  flex-flow: row wrap;

}
.mail {
    font-size: 13px;
    letter-spacing: -0.2px;
    display: block;
    font-weight: 300;
}
.single.single--lva {
  width: 100%;
  flex: 100%;
  max-width: 100%;

  align-items: center;
  img {
    display: inline-block;
    margin: 0 auto;
    max-width: 40%;
  }
  .name {
    text-align: center;
    span {
      font-weight: 300;

    }
  }

}
.single {

  text-align: center;
  margin-bottom: 0.05rem;
  flex: 50%;
  max-width: 50%;
  img {
    border: 4px solid #fff;
    box-sizing: border-box;
    border-radius: 200%;
    margin: 0.5rem;
    max-width: 80%;
    transition: all ease 0.75s;
  }
  .name {
    font-weight: 600;
    font-size: 1.00rem;
    text-align: center;
  }
  &:hover {
    img {
      //transform: scale(1.25);
    }
  }
}
.header {
  padding: 0 1.5rem 0.0rem;
  color: #fff;
  position: relative;
  z-index: 1000;
  .logo {
    max-width: 450px;
    img {

    }
  }
  .menu {
    display: none;
  }
}

.start {
  position: relative;
  z-index: 100;
  //margin: 1rem 0;
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  min-height: 60vh;
  padding: 3rem 0;
  justify-content: center;
  h1,p  {
    color: #fff;
  }
  p {
    font-size: 0.8rem;
  }
  a {
    display: inline;

  }

}

.content {
    box-sizing: border-box;
    padding-left: 2rem;
    padding-right: 2rem;
}
.greybg {
  margin: 2rem 0;
}

.facebook {
  padding: 1.5rem 2rem;
  margin-top: 3rem;
  box-sizing: border-box;
  font-size: 15px;
  text-align: center;
  background-color: rgba(23, 139, 66, 0.55);
  position: absolute;
  width: 100%;
  z-index: 10;
  bottom: 0;
  a{

    color: #fff;
    img {
      max-width: 33px;
      margin-right: 4px;
      max-height: 74px;

    }
  }
}

.partner {
  margin: 1.2rem 0 2rem;
}
.person {
  margin: 1.5rem 0;
}
.person img {
  border-radius: 100%;
  width: 40%;
  margin: 0 2%;
  box-sizing: border;
  border: 5px solid #fff;
}
.footer {
  text-align: center;
  margin: 2rem 0;
  padding-bottom: 60px;
  a{
    color: $text;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      color: $green;
    }
  }
}

.mobilemenu {
  position: fixed;
  background: #fff;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #DDDDDD;
  display: flex;
  justify-content: space-around;
  align-content: center;
  max-height: 70px;
  .icon {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  img {
    max-width: 30px;
  }
}
#fp-nav ul li .fp-tooltip {
  color: $text;
  font-weight: 300;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
#fp-nav ul li a span {
  background: $green;
}
.fp-viewing-start #fp-nav ul li a span {
  background: #fff;
}
.fp-viewing-start #fp-nav ul li .fp-tooltip {
  color: #fff;
}

.impressum .top {
  padding-top: 1rem;
  background: $green;
  background: url('../img/header-neu.jpg') no-repeat 50% 50%;
  background-size: cover;
  color: #fff;
  .row {
    overflow: hidden;
  }
  .center {
    text-align: center;
    justify-content: center;
    align-content: center;
  }
  h1 {
    font-size: 2rem;
  }
}

.impressum .content {
  overflow: hidden;
  max-width: 1600px;
  width: 100%;
  margin: 2.5rem auto;
  display: flex;
  padding: 0 1rem;
  box-sizing: border-box;
  &.headline {
    margin-bottom: 0rem;
  }
  ul {
    display: block;
    li {
      font-weight: 300;
    }
  }
  .content {
    padding: 0;
  }
}



$bar-width: 30px;
$bar-height: 3px;
$bar-spacing: 9px;

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
	height: $bar-height;
}

.hamburger-menu {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(237, 212, 24, 1);
	transition: all 0ms 300ms;
  cursor: pointer;
  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: rgba(237, 212, 24, 1);
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: rgba(237, 212, 24, 1);
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.hamburger-menu.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.mobileoverlay {
  transform: translateY(-100%);
  opacity: 0;
  transition: all ease 0.5s;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: $green;
  ul {
    display: flex;
    align-items:stretch;

    align-items: center;
      flex-wrap: wrap;
      justify-content: center;

    flex-flow: column nowrap;
    flex-direction: column;
    min-height: 80vh;
    li {
        margin: 1.2em 0;
    }
  }
  a {
    color: #fff;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  &.open {
    transform: translateY(00%);
    opacity: 1;
  }
}

.partners img {
  box-sizing: border-box;
  padding-right: 1.4rem;
  /*-webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all ease 0.5s;
  opacity: 0.8;
*/
  &:hover {
    opacity: 1;
    -webkit-filter: grayscale(00%);
    -moz-filter: grayscale(00%);
    -ms-filter: grayscale(00%);
    -o-filter: grayscale(00%);
    filter: grayscale(00%);
  }
}

.rightwrapper {
  padding-top: 2rem;

  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (max-height: 890px) {
    #section4 .contactdata h2 {
      display: none;
    }
}


@media screen and (min-width: 1100px) {
  .mobilemenu {
    display: none;
  }

  .single {
    font-size: 0.8rem;
    flex: 20%;
    .name p {
        font-size: 0.65rem;
    }
  }
  .single.single--lva {
    flex: 20%;
    width: 20%;
    max-width: 20%;
    img {
      max-width: 80%;
    }
    .name span {
      font-size: 0.55rem;
      padding-top: 0.2rem;
      display: inline-block;
    }
  }

  .partners img {
    box-sizing: border-box;
    padding-right: 1.4rem;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all ease 0.5s;
    opacity: 0.8;

    &:hover {
      opacity: 1;
      -webkit-filter: grayscale(00%);
      -moz-filter: grayscale(00%);
      -ms-filter: grayscale(00%);
      -o-filter: grayscale(00%);
      filter: grayscale(00%);
    }
  }
  .content {
      padding-left: 5rem;
      padding-right: 5rem;
  }
  .start {
  //  margin-top: 10vh;
  }
  .header {
    display: flex;
    .logo {
      flex: 33%;
      margin-top: 0.5rem;
    }
    .menu {
      display: block;
      flex: 66%;
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          line-height: 1;
          padding: 0.5rem 1rem;
          a {
            color: #fff;
            font-size: 0.7rem;
            text-transform: uppercase;
            font-weight: 600;
            &:hover {
              color: $yellow;
            }
          }
        }
        li.phone {
          padding: 0.45rem 0.9rem 0.6rem;
          border: 1px solid $yellow;
          margin-left: 0.8rem;
          a {
            font-weight: 300;
          }
        }
      }
    }
  }

  .content ul {
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 1 0 30%;
    }
  }

  #section4 .content {
    padding: 0 3rem;
  }

  .rightwrapper {
    padding: 3rem 5rem 0 5rem;
  }

}

.callus {
  display: none;
}
@media screen and (min-width: 1265px) {
  .mobilemenu {
    display: none;
  }
  .impressum .top {
    padding-top: 0rem;
  }
  .header {
    margin-top: 2rem;
  }
  .content .button {
    margin: 1.5rem 0 0.9rem;
  }
  .content {
      padding-left: 5rem;
      padding-right: 5rem;
  }
  .partner {
    margin: 1.2rem 0 0;
  }
  .facebook {
    width: 34%;
    right: 0;
  }
  .start {
    //margin-top: 20vh;
    width: 66%;
    min-height: 83vh;
    margin-left: 08%;
  }
  .container-fluid .row {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    height: 100vh;
    justify-content: flex-end;
  }

  .impressum {
    .left70 {
          width: 70%;
    }
    .right30 {
          width: 30%;
    }
  }

  .left66 {
    flex: 66%;
    .content {
      max-width: 1120px;
      margin: auto;
    }
  }
  .right33 {
    flex: 34%;
    img {
        max-width: 120%;
        margin-left: -25%;
        position: relative;
    }
  }
  .contactright {
     flex: 28%;

     font-size: 0.9rem;
  }
  .rightwrapper {
    padding: 0 0 0 5%;
  }
  .section {
    padding-top: 0;
  }
  #section1, #section3 {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+66,f7f9f8+66 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, #ffffff 66%, #f7f9f8 66%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ffffff 66%,#f7f9f8 66%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 66%,#f7f9f8 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f9f8',GradientType=1 ); /* IE6-9 */
  }

  #section4 {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+66,f7f9f8+66 */
  background: #f7f9f8; /* Old browsers */
  background: -moz-linear-gradient(left, #f7f9f8 66%, #ffffff 66%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #f7f9f8 66%,#ffffff 66%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #f7f9f8 66%,#ffffff 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f9f8', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  }
  #section0 {
    .background-zoom {
      background-image: url('../img/header-neu.jpg');
    }
  }
  #section2 {

    .about {
    //  background: url('../img/about-desktop.jpg') no-repeat center center;
    //  background-size: cover;
    position: relative;
    overflow: hidden;
    .background-zoom {
      background-image: url('../img/about-desktop.jpg');
    }
    }
    .left66 {
      max-width: 66%;
    }
  }
  .contact {
    height:100%;
    min-height: 300px;
    overflow: hidden;
    position: relative;
    .background-zoom {
      background-image: url('../img/contact.jpg');
    }

  }
  .footer {
    margin: 0;
    padding: 0;
    text-align: left;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    font-size: 0.7rem;
    a{
      font-size: 0.7rem;
      font-weight: 300;
    }
  }
  #fp-nav {
    transform: scale(1.2);
  }

  .callus {
    position: fixed;
    z-index: 10000;
    top: 1.5rem;
    display: flex;
    right: 1.5rem;
    .number {

      transition: all ease 0.5s;
      opacity: 0.0;
      z-index: 150;
      transform: translateX(70px);
      background: #fff;
      position: relative;
      //min-height: 70px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0.01rem 1.1rem;
      font-size: 0.80rem;
      margin-right: 1rem;

      .anrufen {
        display: block;
        font-size: 0.50rem;
        margin-bottom: 0.1rem;
        font-style: italic;
        font-weight: 600;
        color:$green;
      }

      &:after {
        content: '';
        height: 30px;
        width: 15px;
        background: url('../img/tooltip.svg') no-repeat 50% 50%;
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: -11px;
      }
    }
    &:hover {

    .number {
      opacity: 1;
      box-shadow: 6px 6px 21px rgba(0,0,0,0.20);
      transform: translateX(0px);

    }
      img {
        box-shadow: 6px 6px 21px rgba(0,0,0,0.20);
      }
    }
    & > div {
        display: none;

        cursor: pointer;
        img {
          transition: all ease 0.5s;
          position: relative;
          z-index: 200;
          border-radius: 100%;
        }

    }
    div.animated {
      display: flex;
    }
  }
  .fp-viewing-start .callus {
    display: none;
  }
}
@media screen and (min-width: 1850px) {
  #fp-nav {
    transform: scale(1.5);
  }
  .start {
    margin-left: 4%;
    width: 76%;
    h1 {
      font-size: 1.7rem;
      font-style: normal;
    }
    h1 span {
      display: block;
    }
  }
}
